import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import PageSection from '../components/page-section'

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout location={location} pageTitle="404: Not Found">

      <PageSection title="404: Not Found">
        <p>
          Sorry, we could not find this page.
          Please return to the <Link to="/">home page</Link>.
        </p>
      </PageSection>

    </Layout>
  )
}

export default NotFoundPage
